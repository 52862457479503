.item {
  border: 1px solid #000000;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

body {  
  width: 100%;
  max-height: 100vh;
  margin: 0;
  font-family: sans-serif;
  line-height: 1.5;
}

.box {
  width: 100%;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

section {
  width: 100%;
  display: flex;
  text-align: center;
  padding: 5%;
  flex-direction: column;
  align-items: center;
  align-self: center;
  
}


@media only screen and (max-width: 600px) {
  body {
    width: 100vw;
    max-width: 100vw;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}
.mapboxgl-canvas {
  position: static !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}
@media only screen and (max-width: 600px) {
  .mapboxgl-canvas {
    position: static !important;
    width: 100% !important;
    height: 90vh !important;
    border-radius: 10px;
  }
}


.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 40px;
  border-radius: 4px;
}

.directionsRight {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  margin: 12px;
  border-radius: 12px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.directionsRight:active {
  cursor: pointer;
  box-shadow: 0 0 5px 1px rgba(35, 55, 75, 0.9);
}

.directionsLeft {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 12px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.directionsLeft:active {
  cursor: pointer;
  box-shadow: 0 0 5px 1px rgba(35, 55, 75, 0.9);
}

.directionsCenter {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;

  position: absolute;
  border-radius: 12px;
  width: 250px;
  height: 50px;
  display: flex;
}

.directionsCenter:active {
  cursor: pointer;
  box-shadow: 0 0 5px 1px rgba(35, 55, 75, 0.9);
}

@media only screen and (max-width: 600px) {
  .directionsLeft {
    top: 65px;
  }
  .directionsRight {
    top: 65px;
  }
  .directionsCenter {
    width: auto;
    margin: 12px;
  }
}

.directionsLeftSet {
  padding: 1px;
  top: 60px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  align-items: center;
  width: 300px;
  background-color: rgba(35, 55, 75, 0.8);
}

@media only screen and (max-width: 600px) {
  .directionsLeftSet {
    width: auto;
    right: 0;
    top: 125px;
  }
}

.scan-qr {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 250px;
}

@media only screen and (max-width: 600px) {
  .scan-qr {
    visibility: hidden;
  }
}

.floor-selection {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding-left: 2px;
  padding-right: 2px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 12px;
  border-radius: 12px;
  height: 70px;
  width: 250px;
  display: flex;
  font-size: 16px;
}

.floor-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  cursor: pointer;
}
.floor-item:hover {
  background-color: rgba(67, 93, 117, 0.9);
  border-radius: 12px;
}
.floor-item:active {
  box-shadow: 0 0 1px 1px rgba(35, 55, 75, 0.9);
}

::-webkit-scrollbar {
  height: 8px;
}
::-webkit-scrollbar-thumb:horizontal{
  background: rgb(17, 28, 38);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  margin: 12px !important;
}
.directionGrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 10px;
  justify-items: center;
  align-items: center;
}

.directionGrid:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.directionGrid:nth-child(odd) {
  background-color: rgba(86, 104, 124, 0.8);
}


.buttonCss {
  
  display: inline-block;
  outline: 0;
  border:0;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  color: #000;
  background: #fff;
  line-height: 30px;
  border-radius: 40px;
  padding: 20px;
  font-size: 30px;
  font-weight: 600;
  box-shadow: rgb(255, 198, 0) -2px -2px 0px 2px, rgb(246, 84, 174) 0px 0px 0px 4px, rgba(0, 0, 0, 0.05) 0px 0px 2px 7px;
  transition: all 0.2s;
  :hover{
      box-shadow: rgb(246, 84, 174) -2px -2px 0px 2px, rgb(255, 198, 0) 0px 0px 0px 4px, rgba(0, 0, 0, 0.05) 0px 0px 2px 7px;
      transform: scale(1.01);
  }
  
}




/* Container for the image grid */
.image-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 columns on larger screens */
  gap: 10px; /* Space between grid items */
  max-width: 1200px;
  margin: 0 auto; /* Center container */
  padding: 10px; /* Optional padding around the grid */
}

/* Making sure images fill their cells without overflow */
.image-grid img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover; /* optional: ensures images cover the entire cell */
}

.image-grid video {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover; /* If your video dimensions vary, this helps maintain a uniform grid cell */
}

/* Responsive breakpoint for smaller screens: 3 images per row */
@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
/*
@media (max-width: 480px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
  */